/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import { RecoilRoot } from 'recoil'
import Preloader from "./Preloader"

const Layout = ({ children }) => {
  const [loader, setLoader] = React.useState(true)

  React.useEffect(() => {
      setTimeout(() => setLoader(false), 2000);
  }, [])

  return (
    <RecoilRoot>
      {children}
      {/* {loader ? <Preloader /> : null} */}
    </RecoilRoot>
  )
}

export default Layout
