import { faHeartbeat } from '@fortawesome/free-solid-svg-icons'
import React from 'react'

const Heartbeat = ({ className }) => {
    return (
        <div className={`heartbeat ${className}`}>
        </div>
    )
}

export default Heartbeat
